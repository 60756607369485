import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';

import ChamaAzul from '../views/chama_azul/ChamaAzul.vue';
import GeradorAzul from '../views/chama_azul/GeradorAzul.vue';
import ReikiAzul from '../views/chama_azul/ReikiAzul.vue';
import SimbolosAzul from '../views/chama_azul/SimbolosAzul.vue';

import ChamaVioleta from '../views/chama_violeta/ChamaVioleta.vue';
import ReikiVioleta from '../views/chama_violeta/ReikiVioleta.vue';
import GeradorVioleta from '../views/chama_violeta/GeradorVioleta.vue';
import LaserVioleta from '../views/chama_violeta/LaserVioleta.vue';
import SimbolosVioleta from '../views/chama_violeta/SimbolosVioleta.vue';

import BastaoAtlantes from '../views/conteudo_gratuito/BastaoAtlantes.vue';
import CristaisEtericos from '../views/cristais_etericos/CristaisEtericos.vue';
import FloraisEtericos from '../views/florais_etericos/FloraisEtericos.vue';

import ConselhoCarmico from '../views/ebooks/ConselhoCarmico.vue';
import AssinaturaSemestral from '../views/assinatura_semestral/AssinaturaSemestral.vue';
import VerificaEmail from '../views/captura_emails/VerificaEmail.vue';
import DownloadEbook from '../views/ebooks/DownloadEbook.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/chama_azul',
    name: 'ChamaAzul',
    component: ChamaAzul,
  },
  {
    path: '/gerador_azul',
    name: 'GeradorAzul',
    component: GeradorAzul,
  },
  {
    path: '/reiki_azul',
    name: 'ReikiAzul',
    component: ReikiAzul,
  },
  {
    path: '/simbolos_azul',
    name: 'SimbolosAzul',
    component: SimbolosAzul,
  },
  {
    path: '/chama_violeta',
    name: 'ChamaVioleta',
    component: ChamaVioleta,
  },
  {
    path: '/reiki_violeta',
    name: 'ReikiVioleta',
    component: ReikiVioleta,
  },
  {
    path: '/gerador_violeta',
    name: 'GeradorVioleta',
    component: GeradorVioleta,
  },
  {
    path: '/laser_violeta',
    name: 'LaserVioleta',
    component: LaserVioleta,
  },
  {
    path: '/simbolos_violeta',
    name: 'SimbolosVioleta',
    component: SimbolosVioleta,
  },
  {
    path: '/bastao_atlantes',
    name: 'BastaoAtlantes',
    component: BastaoAtlantes,
  },
  {
    path: '/cristais_etericos',
    name: 'CristaisEtericos',
    component: CristaisEtericos,
  },
  {
    path: '/florais_etericos',
    name: 'FloraisEtericos',
    component: FloraisEtericos,
  },
  {
    path: '/conselho_carmico',
    name: 'ConselhoCarmico',
    component: ConselhoCarmico,
  },
  {
    path: '/assinatura_semestral',
    name: 'AssinaturaSemestral',
    component: AssinaturaSemestral,
  },
  {
    path: '/verificar_email',
    name: 'VerificaEmail',
    component: VerificaEmail,
  },
  {
    path: '/download_ebook',
    name: 'DownloadEbook',
    component: DownloadEbook,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
