<template>
  <div class="conteudo">
    <ul class="list-group list-group-horizontal-sm boxitems">
      <li class="list-group-item coluna">
        <div class="col coluna">
          <div class="row boxicon">
            <svg
              class="icone"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 496 512"
            >
              <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm105.6-151.4c-25.9 8.3-64.4 13.1-105.6 13.1s-79.6-4.8-105.6-13.1c-9.9-3.1-19.4 5.4-17.7 15.3 7.9 47.1 71.3 80 123.3 80s115.3-32.9 123.3-80c1.6-9.8-7.7-18.4-17.7-15.3zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"
              />
            </svg>
          </div>
          <div class="row itens">
            <h4>
              Você vai conhecer o que é o Sistema de Cura Energética de São
              Miguel
            </h4>
          </div>
        </div>
      </li>
      <li class="list-group-item coluna">
        <div class="col coluna">
          <div class="row boxicon">
            <svg
              class="icone"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M192 352C192 316.7 220.7 288 256 288C291.3 288 320 316.7 320 352C320 387.3 291.3 416 256 416C220.7 416 192 387.3 192 352zM103 135C112.4 125.7 127.6 125.7 136.1 135L160 158.1L183 135C192.4 125.7 207.6 125.7 216.1 135C226.3 144.4 226.3 159.6 216.1 168.1L193.9 192L216.1 215C226.3 224.4 226.3 239.6 216.1 248.1C207.6 258.3 192.4 258.3 183 248.1L160 225.9L136.1 248.1C127.6 258.3 112.4 258.3 103 248.1C93.66 239.6 93.66 224.4 103 215L126.1 192L103 168.1C93.66 159.6 93.66 144.4 103 135V135zM295 135C304.4 125.7 319.6 125.7 328.1 135L352 158.1L375 135C384.4 125.7 399.6 125.7 408.1 135C418.3 144.4 418.3 159.6 408.1 168.1L385.9 192L408.1 215C418.3 224.4 418.3 239.6 408.1 248.1C399.6 258.3 384.4 258.3 375 248.1L352 225.9L328.1 248.1C319.6 258.3 304.4 258.3 295 248.1C285.7 239.6 285.7 224.4 295 215L318.1 192L295 168.1C285.7 159.6 285.7 144.4 295 135V135zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
              />
            </svg>
          </div>
          <div class="row itens">
            <h4>
              Você vai aprender como se livrar das doenças da alma utilizando o
              Sistema de Cura Energética de São Miguel
            </h4>
          </div>
        </div>
      </li>
      <li class="list-group-item coluna">
        <div class="col coluna">
          <div class="row boxicon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icone"
              viewBox="0 0 512 512"
            >
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M320 336C333.3 336 344 346.7 344 360C344 373.3 333.3 384 320 384H192C178.7 384 168 373.3 168 360C168 346.7 178.7 336 192 336H320zM136.4 224C136.4 210.7 147.1 200 160.4 200C173.6 200 184.4 210.7 184.4 224C184.4 237.3 173.6 248 160.4 248C147.1 248 136.4 237.3 136.4 224zM80 224C80 179.8 115.8 144 160 144C204.2 144 240 179.8 240 224C240 268.2 204.2 304 160 304C115.8 304 80 268.2 80 224zM160 272C186.5 272 208 250.5 208 224C208 197.5 186.5 176 160 176C133.5 176 112 197.5 112 224C112 250.5 133.5 272 160 272zM376.4 224C376.4 237.3 365.6 248 352.4 248C339.1 248 328.4 237.3 328.4 224C328.4 210.7 339.1 200 352.4 200C365.6 200 376.4 210.7 376.4 224zM432 224C432 268.2 396.2 304 352 304C307.8 304 272 268.2 272 224C272 179.8 307.8 144 352 144C396.2 144 432 179.8 432 224zM352 176C325.5 176 304 197.5 304 224C304 250.5 325.5 272 352 272C378.5 272 400 250.5 400 224C400 197.5 378.5 176 352 176zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464z"
              />
            </svg>
          </div>
          <div class="row itens">
            <h4>
              Você também aprenderá como salvar vidas utilizando o Sistema de
              Cura Energética de São Miguel
            </h4>
          </div>
        </div>
      </li>
      <li class="list-group-item coluna">
        <div class="col coluna">
          <div class="row boxicon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icone"
              viewBox="0 0 576 512"
            >
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M400 256C400 317.9 349.9 368 288 368C226.1 368 176 317.9 176 256C176 194.1 226.1 144 288 144C349.9 144 400 194.1 400 256zM272 224V288H264C255.2 288 248 295.2 248 304C248 312.8 255.2 320 264 320H312C320.8 320 328 312.8 328 304C328 295.2 320.8 288 312 288H304V208C304 199.2 296.8 192 288 192H272C263.2 192 256 199.2 256 208C256 216.8 263.2 224 272 224zM0 128C0 92.65 28.65 64 64 64H512C547.3 64 576 92.65 576 128V384C576 419.3 547.3 448 512 448H64C28.65 448 0 419.3 0 384V128zM48 176V336C83.35 336 112 364.7 112 400H464C464 364.7 492.7 336 528 336V176C492.7 176 464 147.3 464 112H112C112 147.3 83.35 176 48 176z"
              />
            </svg>
          </div>
          <div class="row itens">
            <h4>
              Você ainda descobrirá como ganhar uma renda extra aplicando as
              técnicas do Sistema de Cura Energética de São Miguel
            </h4>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Listagem',
};
</script>

<style scoped>
.coluna {
  background-color: rgba(14, 40, 209, 0.98);
  border: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.titulo {
  -sizing: border-box;
  color: rgb(255, 255, 255);
  height: 300;
  font-family: Lato;
  font-size: 42.6667px;
  font-weight: 700;
  text-align: center;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icone {
  fill: rgb(68, 235, 244);
  height: 80px;
  line-height: 80px;
  width: 80px;
  margin: auto;
  padding: auto;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.itens {
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 19.8px;
  text-align: center;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.conteudo {
  background-color: rgba(14, 40, 209, 0.98);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 20;
}
.boxitems {
  width: auto;
  padding: 0;
  margin: 0;
}
</style>
