<template>
  <div class="d-flex justify-content-center">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/hO-83m-TWuM"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'PresentationVideo',
};
</script>
